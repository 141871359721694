<template>
  <div class="w-p-100 h-p-100 bg-f3">
    <van-form
      class="h-p-100 scroll-y w-p-100"
      @submit="onSubmit"
      :ref="querySubmit"
    >
      <van-cell-group inset class="h-calc-45 scroll-y margin-0 bg-transparent">
        <van-field name="uploader" label="现场照片" class="phonecell">
          <template #input>
            <div
              class="flex relative"
              v-for="(items, index) in fileList"
              :key="index"
            >
              <van-image
                @click="showImg(fileList)"
                :src="items.url"
                width="80px"
                height="80px"
              ></van-image>
              <div class="absolute r-0 h-20 w-20 flex ">
                <van-icon
                  name="cross"
                  size="20"
                  color="#ff0000"
                  @click="previewdel(index)"
                />
              </div>
            </div>
            <div class="w-p-100">
              <van-button size="mini" type="primary" @click="takephoto('null')"
                >拍照上传</van-button
              >
              <van-button size="mini" type="primary" @click="checkphoto('null')"
                >相册上传</van-button
              >
            </div>
          </template>
          <!-- <template #button>
                        
                    </template> -->
        </van-field>
        <van-field
          v-model="state.code"
          name="picker"
          label="违规项编号"
          placeholder="提交后自动生成"
          readonly
        />
        <van-field
          readonly
          v-model="query.violation_date"
          name="picker"
          label="违规日期"
          placeholder="必填项"
          @click="showCalendar = true"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          is-link
          readonly
          v-model="state.ct_contractor_info_id"
          name="picker"
          label="承包商名称"
          @click="picker.ct_contractor_info_id = true"
          placeholder="必填项"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          v-model="query.ct_contractor_info_code"
          name="picker"
          label="承包商编码"
          placeholder="自动关联名称"
        />
        <van-field
          is-link
          readonly
          v-model="state.ct_operator_info_id"
          name="picker"
          label="违规人员"
          @click="picker.ct_operator_info_id = true"
          placeholder="必填项"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          is-link
          readonly
          v-model="state.role_name"
          name="picker"
          label="角色"
          @click="picker.role_name = true"
          placeholder="自动关联人员"
        />
        <van-field
          is-link
          readonly
          v-model="state.org_area_id"
          name="picker"
          label="区域"
          @click="picker.org_area_id = true"
          placeholder="必填项"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          is-link
          readonly
          v-model="state.ptw_cetificate_type_id"
          name="picker"
          label="作业类型"
          @click="picker.ptw_cetificate_type_id = true"
          placeholder="必填项"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          v-model="query.work_description"
          name="picker"
          label="作业描述"
          placeholder="选填项"
        />
        <van-field
          is-link
          readonly
          v-model="state.ct_violation_types_1"
          name="picker"
          label="违规大类"
          @click="picker.ct_violation_types_1 = true"
          placeholder="必填项"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          is-link
          readonly
          v-model="state.ct_violation_types_2"
          name="picker"
          label="违规小类"
          @click="picker.ct_violation_types_2 = true"
          placeholder="选填项"
        />
        <van-field
          is-link
          readonly
          v-model="state.violation_stage"
          name="picker"
          label="违规阶段"
          @click="picker.violation_stage = true"
          placeholder="选填项"
        />
        <van-field
          v-model="query.violation_description"
          name="picker"
          label="违规描述"
          placeholder="必填项"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          is-link
          readonly
          v-model="state.work_charger"
          name="picker"
          label="作业负责人"
          @click="picker.work_charger = true"
          placeholder="选填项"
        />
        <van-field
          is-link
          readonly
          v-model="state.project_charger"
          name="picker"
          label="项目负责人"
          @click="picker.project_charger = true"
          placeholder="自动关联名称"
        />
        <van-field
          is-link
          readonly
          v-model="state.create_by"
          v-if="state.code !=''"
          name="picker"
          label="发现人"
        />
        <!-- 下拉内容 -->
        <van-popup
          v-model:show="picker.ct_contractor_info_id"
          position="bottom"
        >
          <van-search :placeholder="placeholder" v-model="ct_contractor_info_id_option_search" />
          <van-picker
            :columns="optionFilter(options.ct_contractor_info_id,ct_contractor_info_id_option_search)"
            @confirm="
              data => {
                confirm(data, 'ct_contractor_info_id');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.ct_contractor_info_id = false"
          />
        </van-popup>
        <van-popup v-model:show="picker.ct_operator_info_id" position="bottom">
          <van-search :placeholder="placeholder" v-model="ct_operator_info_id_option_search" />
          <van-picker
            :columns="optionFilter(options.ct_operator_info_id,ct_operator_info_id_option_search)"
            @confirm="
              data => {
                confirm(data, 'ct_operator_info_id');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.ct_operator_info_id = false"
          />
        </van-popup>
        <van-popup v-model:show="picker.work_charger" position="bottom">
          <van-search :placeholder="placeholder" v-model="work_charger_option_search" />
          <van-picker
            :columns="optionFilter(options.work_charger,work_charger_option_search)"
            @confirm="
              data => {
                confirm(data, 'work_charger');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.work_charger = false"
          />
        </van-popup>
        <van-popup v-model:show="picker.role_name" position="bottom">
          <van-picker
            :columns="options.role_name"
            @confirm="
              data => {
                confirm(data, 'role_name');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.role_name = false"
          />
        </van-popup>
        <van-popup v-model:show="picker.org_area_id" position="bottom">
          <van-picker
            :columns="options.org_area_id"
            @confirm="
              data => {
                confirm(data, 'org_area_id');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.org_area_id = false"
          />
        </van-popup>
        <van-popup
          v-model:show="picker.ptw_cetificate_type_id"
          position="bottom"
        >
          <van-picker
            :columns="options.ptw_cetificate_type_id"
            @confirm="
              data => {
                confirm(data, 'ptw_cetificate_type_id');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.ptw_cetificate_type_id = false"
          />
        </van-popup>
        <van-popup v-model:show="picker.ct_violation_types_1" position="bottom">
          <van-picker
            :columns="options.ct_violation_types_1"
            @confirm="
              data => {
                confirm(data, 'ct_violation_types_1');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.ct_violation_types_1 = false"
          />
        </van-popup>
        <van-popup v-model:show="picker.ct_violation_types_2" position="bottom">
          <van-picker
            :columns="options.ct_violation_types_2"
            @confirm="
              data => {
                confirm(data, 'ct_violation_types_2');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.ct_violation_types_2 = false"
          />
        </van-popup>
        <van-popup v-model:show="picker.violation_stage" position="bottom">
          <van-picker
            :columns="options.violation_stage"
            @confirm="
              data => {
                confirm(data, 'violation_stage');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.violation_stage = false"
          />
        </van-popup>
        <van-popup v-model:show="picker.project_charger" position="bottom">
          <van-search :placeholder="placeholder" v-model="project_charger_option_search" />
          <van-picker
            :columns="optionFilter(options.project_charger,project_charger_option_search)"
            @confirm="
              data => {
                confirm(data, 'project_charger');
              }
            "
            :columns-field-names="{ text: 'label', value: 'value' }"
            @cancel="picker.project_charger = false"
          />
        </van-popup>
        <van-calendar
          v-model:show="showCalendar"
          @confirm="calendarOnConfirm"
          :min-date="new Date('2000-01-01')"
        />
      </van-cell-group>
      <div class="flex">
        <van-button
          block
          type="default"
          class="h-45 bg-808080 color-fff"
          @click="
            () => {
              goToHistory = true;
              onSearch(1);
            }
          "
          >历史记录</van-button
        >
        <van-button
          block
          type="warning"
          class="h-45"
          @click="add()"
          v-if="query.id != ''"
          >新增</van-button
        >
        <van-button
          block
          type="primary"
          class="h-45"
          :disabled="isloading"
          :loading="isloading"
          loading-text="提交中"
          native-type="submit"
          v-if="query.id == '' || status == 1"
          >保存并提交</van-button
        >
      </div>
    </van-form>
    <!--历史记录-->
    <van-popup
      v-model:show="goToHistory"
      position="bottom"
      style="height:calc(100% - 40px)"
      :closeable="false"
      :safe-area-inset-bottom="true"
      :close-on-click-overlay="false"
      :overlay="false"
    >
      <div>
        <van-search
          v-model="search.keyword"
          show-action
          background="#F3F3F3"
          @search="onSearch(1)"
          placeholder="关键字查询"
        >
          <template #action>
            <div class="flex alignCenter">
              <van-field
                is-link
                readonly
                class="searchInputBox"
                v-model="searchDisplay.status"
                name="picker"
                placeholder="处理状态"
                @click="showStatusOption = true"
              />
              <!-- <van-field class="searchInputBox" v-model="query.date_time" readonly name="calendar" placeholder="点击选择日期" @click="showCalendar = true"/> -->
              <van-icon
                name="search"
                class="size-25"
                color="#0F60A7"
                @click="onSearch(1)"
              />

              <van-popup v-model:show="showStatusOption" position="bottom">
                <van-picker
                  :columns="options.statusSearch"
                  @confirm="
                    data => {
                      searchDisplay.status = data.label;
                      search.status = data.value;
                      showStatusOption = false;
                    }
                  "
                  :columns-field-names="{ text: 'label', value: 'value' }"
                  @cancel="showStatusOption = false"
                />
              </van-popup>
            </div>
          </template>
        </van-search>
      </div>
      <div class="h-calc-54">
        <van-pull-refresh
          v-model="loading"
          @refresh="refresh"
          @load="onLoad"
          :finished="finished"
          finished-text="没有更多了"
          class="h-calc-45 scroll-y"
        >
          <div v-if="list.length > 0" class="w-p-100 h-p-100">
            <div
              class="w-calc-48 Fbg lP-24 rP-24 bM-4 tP-5 bP-5 relative"
              v-for="item in list"
              :key="item"
            >
              <div class="h-45 flex alignCenter justifyBetween">
                <div class="size-20">{{ item.ct_operator_info_id }}</div>
                <div class="size-18 color-80">{{ item.violation_date }}</div>
              </div>

              <div class="h-35 flex alignCenter size-14 color-80">
                状态：<span
                  class="padding-5 color-fff radius-4"
                  :class="{
                    'bg-DFAE00': item.tempstatus == 1,
                    'bg-2FB234': item.tempstatus != 1
                  }"
                  >{{ item.tempstatus == 1 ? "待提交" : "已提交" }}</span
                >
              </div>
              <div class="h-35 flex alignCenter size-14 color-80">
                作业类型：{{ item.ptw_cetificate_type_id }}
              </div>
              <div
                class="h-35 flex alignCenter justifyBetween size-14 color-80"
              >
                违规小类：{{ item.ct_violation_types_2 }}
                <div class="flex r-24 b-12 alignCenter justifyEnd">
                  <div
                    class="size-14 flex alignCenter justifyCenter bg-0F60A7 color-fff padding-5 lM-5 radius-4"
                    @click="getDetail(item)"
                  >
                    详情
                  </div>
                  <div
                    class="size-14 flex alignCenter justifyCenter bg-0F60A7 color-fff padding-5 lM-5 radius-4"
                    @click="del(item.id)"
                  >
                    删除
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <van-empty description="暂无数据"></van-empty>
          </div>
        </van-pull-refresh>
        <van-button
          block
          type="default"
          class="bg-808080 color-fff"
          @click="goToHistory = false"
          >关闭</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
<script>
/* eslint-disable */
import {onMounted, reactive,ref,watch} from 'vue';
import http from '../../../api/http';
import global from '../../../api/global';
import {Toast,Notify,Dialog, ImagePreview} from 'vant'
import dayjs from 'dayjs';
export default {
    setup() {
        const goToHistory = ref(false);
        const showStatusOption = ref(false);
        const finished = ref(false);
        const loading = ref(false);
        const list = ref([]);
        const showCalendar = ref(false);
        const query = reactive({
            id:'',
            method:'saveorupdate',
            t:'CT_VIOLATION_CASE',
            action:'success',
            attachment1:[],
            code:'',
            violation_date:'',
            ct_contractor_info_id:'',
            ct_contractor_info_code:'',
            ct_operator_info_id:'',
            role_name:[],
            org_area_id:'',
            ptw_cetificate_type_id:'',
            work_description:'',
            ct_violation_types_1:'',
            ct_violation_types_2:'',
            violation_stage:'',
            violation_description:'',
            work_charger:'',
            project_charger:'',
        });
        const fileList = ref([]);
        const state = reactive({
            attachment1:[],
            code:'',
            violation_date:'',
            ct_contractor_info_id:'',
            ct_contractor_info_code:'',
            ct_operator_info_id:'',
            role_name:[],
            org_area_id:'',
            create_by:"",
            ptw_cetificate_type_id:'',
            work_description:'',
            ct_violation_types_1:'',
            ct_violation_types_2:'',
            violation_stage:'',
            violation_description:'',
            work_charger:'',
            project_charger:'',
        });

        const imgUrl = http.getImgUrl();
        const ct_contractor_info_id_option_search = ref('')
        const ct_operator_info_id_option_search = ref('')
        const work_charger_option_search = ref('')
        const project_charger_option_search = ref('')
        const options= reactive({
            ct_contractor_info_id:[],
            ct_operator_info_id:[],
            work_charger:[],
            role_name:[],
            org_area_id:[],
            ptw_cetificate_type_id:[],
            ct_violation_types_1:[],
            ct_violation_types_2:[],
            violation_stage:[],
            project_charger:[],
            status:[],
            statusSearch:[],
        });
        const optionFilter = (option,query) =>{
          if (query === null) {
            query = ''
          }
          if (option && option.length > 0) {
            const arr = option.filter((item) => item.label.includes(query))
            return arr;
          }
        }
        const picker= reactive({
            ct_contractor_info_id:false,
            ct_operator_info_id:false,
            work_charger:false,
            role_name:false,
            org_area_id:false,
            ptw_cetificate_type_id:false,
            ct_violation_types_1:false,
            ct_violation_types_2:false,
            violation_stage:false,
            project_charger:false,
        });
        const search = reactive({
            status:'',
            keyword:'',
            t:'CT_VIOLATION_CASE',
            method:'query',
            queryId:1000,
            page:1,
            pageSize:20
         })
         const searchDisplay = reactive({
            status: '',
         })

        const querySubmit = ref(null);

        //图片上传完成
        const afterRead = (data) => {
            let formData = new FormData();
            formData.append('file', data.file, data.file.name);
            http.fileuoload(formData)
            .then(res=>{
                query.attachment1.push({document_path:res});
                fileList.value.forEach((elem,index)=>{
                    if(elem.status == 'success'){
                        fileList.value.splice(index,1)
                    }
                });
                fileList.value.push({url:imgUrl + res});
            })
            .catch(()=>{
                data.status = 'faild';
                fileList.value.forEach((elem,index)=>{
                    if(!elem.status || elem.status == 'faild'){
                        fileList.value.splice(index,1)
                    }
                });
            })
        };
        
        const getSelectList = () =>{
            http.get({
                method:'listMultiPardata',
                t:'CT_VIOLATION_CASE',
                prop:'status,ct_contractor_info_id,ct_operator_info_id,role_name,org_area_id,ptw_cetificate_type_id,violation_stage,ct_violation_types_1,ct_violation_types_2,project_charger,work_charger'
            })
            .then(res=>{
                options.status = res.status;
                options.ct_contractor_info_id = res.ct_contractor_info_id;
                options.ct_operator_info_id = res.ct_operator_info_id;
                options.role_name = res.role_name;
                options.org_area_id = res.org_area_id;
                options.ptw_cetificate_type_id = res.ptw_cetificate_type_id;
                options.violation_stage = res.violation_stage;
                options.ct_violation_types_1 = res.ct_violation_types_1;
                options.ct_violation_types_2 = res.ct_violation_types_2;
                options.project_charger = res.project_charger;
                options.work_charger = res.work_charger;
            })
        }

        const getStatusList = () =>{
            http.get({
                method:'query',
                queryId:1105,
                p_index: 'TrainAppoint',
            })
            .then(res=>{
                var allStatus = [{label:"全部",value:""}]
                var status = res.map.map((item)=>{
                    return {label: item.label,value: item.value}
                })
                options.statusSearch = allStatus.concat(status);
            })
            
        }

        // 根据承包商 获取承包商的编号
        const changeCtContractor = () =>{
            http.get({
                method:'detail',
                t:'CT_CONTRACTOR_INFO',
                id:query.ct_contractor_info_id
            })
            .then(res=>{
                var dMaps = res.map;
                query.ct_contractor_info_code = dMaps.code;
                query.project_charger = dMaps.project_charger;
                options.project_charger.forEach(item => {
                    if (item.value == dMaps.project_charger) {
                        state.project_charger = item.label;
                    }
                })
            });

            http.get({
                method:'listMultiPardata',
                t:'CT_VIOLATION_CASE',
                ct_contractor_info_id:query.ct_contractor_info_id,
                prop:'ct_operator_info_id,work_charger'
            })
            .then(res=>{
                options.ct_operator_info_id = res.ct_operator_info_id;
                options.work_charger = res.work_charger;
            })
        }
        // 根据违规人员获取违规人员角色
        const changeCtOperator = ()=>{
            if(query.ct_operator_info_id == '')  return query.role_name =[]; 
            http.get({
                method:'listPardata',
                t:'CT_VIOLATION_CASE',
                prop:'role_name',
                ct_operator_info_id:query.ct_operator_info_id
            })
            .then(res=>{
                options.role_name = res;
            });
        }

        const confirm = (data,type) =>{
            query[type] = data.value;
            state[type] = data.label;
            picker[type] = false;
            if (type == 'ct_contractor_info_id'){
                changeCtContractor();
            }
            if (type == 'ct_operator_info_id'){
                changeCtOperator();
            }            
        }

        const onSearch = (page) =>{
            search.page = page;
            loading.value = false;
            Toast.loading({message: '加载中...',duration:0});
            http.get(search)
            .then(res=>{
                Toast.clear();
                list.value = res.map;
            })
            // finished.value = true;
        }
        const isloading = ref(false)
        const onSubmit = () =>{
            Dialog.confirm({
                title: '提示',
                message:'此操作将提交此次的违规，请确认？',
            })
            .then(()=>{
                isloading.value = true;
                http.post(query)
                .then(()=>{
                    isloading.value = false;
                    Notify({ type: 'success', message: '保存成功' });
                })
                .catch(()=>{
                    isloading.value = false;
                })
            })
            .catch(()=>{

            })
            
        }

        const status = ref(1)
        const getDetail = (item) =>{
            goToHistory.value = false;
            http.get({
                method:'detailJob',
                t:'CT_VIOLATION_CASE',
                id:item.id
            })
            .then(res=>{
                status.value = res.status;
                ;
                var dMap = res;
                const flist = [];
                Object.keys(query).map(key=>{
                    if(key!='method'&&key!='t' &&key!='action'){
                        query[key] = dMap[key]
                    }
                });
                
                if(dMap.attachment1 && dMap.attachment1.length > 0){
                    dMap.attachment1.forEach(element => {
                        flist.push({url:imgUrl + element.document_path})
                    });
                 }
                fileList.value = flist;
                state.code = item.code
                state.ct_contractor_info_id = item.ct_contractor_info_id
                state.ct_operator_info_id = item.ct_operator_info_id
                state.role_name = item.role_name
                state.org_area_id = item.org_area_id
                state.ptw_cetificate_type_id = item.ptw_cetificate_type_id
                state.ct_violation_types_1 = item.ct_violation_types_1
                state.ct_violation_types_2 = item.ct_violation_types_2
                state.violation_stage = item.violation_stage
                state.work_charger = item.work_charger
                state.project_charger = item.project_charger
                state.create_by = item.create_by

            })
        }

        // 删除
        const del = (ids) =>{
            Dialog.confirm({
                message:'此操作将删除所选数据,请确认？',
            })
            .then(() => {
                http.post({
                    method:'delete',
                    t:'CT_VIOLATION_CASE',
                    ids:ids
                })
                .then(()=>{
                    onSearch(1);
                })
            })
            .catch(()=>{}) 
        }

        // 下拉刷新
        const refresh = () =>{
            loading.value = true;
            onSearch(1)
        }

        const calendarOnConfirm = (date) =>{
            query.violation_date = dayjs(date).format('YYYY-MM-DD');//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            showCalendar.value = false;
        }

        onMounted(()=>{
            getSelectList();
            getStatusList();
        });
        // 违规大类 联动出违规小类
        watch(()=>query.ct_violation_types_1,(newval,oldval)=>{
            if(newval!=oldval){
                options.ct_violation_types_2 = [];
                http.get({
                    method:'listPardata',
                    t:'CT_VIOLATION_CASE',
                    prop:'ct_violation_types_2',
                    ct_violation_types_1:newval
                })
                .then(res=>{
                    options.ct_violation_types_2 = res;
                    if(global.isHasValue(query.ct_violation_types_2,res) == 0){
                        query.ct_violation_types_2 = '';
                        state.ct_violation_types_2 = '';
                    }
                });
            }
        });


        const previewdel = (num) =>{
            fileList.value.forEach((elem,index)=>{
                if(num == index){
                    fileList.value.splice(index,1);
                    query.attachment1.splice(index,1)
                }
            });
        }

        const showImg = (list) =>{
            var images = [];
            list.forEach(elem =>{
                images.push(elem.url);
            });
            ImagePreview({
                images: images,
                closeable:true,
                startPosition: 1,
                loop:true
            });
        }
        const imageurl = ref('');
        const onSuccess = (data) =>{
            imageurl.value = "data:image/png;base64," + data
            var file = http.dataURLtoFile(imageurl.value);
            let formData = new FormData();
            formData.append('file', file, file.name);
            http.fileuoload(formData)
            .then(res=>{
                query.attachment1.push({document_path:res});
                fileList.value.forEach((elem,index)=>{
                    if(elem.status == 'success'){
                        fileList.value.splice(index,1)
                    }
                });
                fileList.value.push({url:imgUrl + res});
                http.loading.close()
            })
            .catch(()=>{
                data.status = 'faild';
                fileList.value.forEach((elem,index)=>{
                    if(!elem.status || elem.status == 'faild'){
                        fileList.value.splice(index,1)
                    }
                });
                http.loading.close()
            })
        }
        // 拍照
        const takephoto = (index) =>{
            navigator.camera.getPicture(
                function(data){
                    onSuccess(data,index)
                }, 
                function(err){
                    alert(err.toString())
                }, 
                {
                    quality: 50,
                    destinationType: Camera.DestinationType.DATA_URL,//返回FILE_URI类型
                    sourceType: Camera.PictureSourceType.CAMERA,//指定图片来自拍照
                    // cameraDirection: Camera.Direction.FRONT,//指定前后摄像头--好像没起作用
                    encodingType: Camera.EncodingType.PNG,
                    targetWidth: 150,
                    targetHeight: 150,
                    saveToPhotoAlbum:false
                });
        }
        // 从相册选择
        const checkphoto = (index) =>{
            navigator.camera.getPicture(
                function(data){
                    onSuccess(data,index)
                }, 
                function(err){
                    alert(err.toString())
                }, {
                quality: 50,
                destinationType: Camera.DestinationType.DATA_URL,//返回Base64编码字符串
                sourceType: Camera.PictureSourceType.SAVEDPHOTOALBUM,//指定图片来自相册
                encodingType: Camera.EncodingType.PNG,//指定返回图片是png格式还是jpeg
                targetWidth: 150,
                targetHeight: 150
            });
        }

        const add = () =>{
            Object.keys(query).map(key =>{
                if(key!='method' && key!='t'){
                    query[key] = key == 'attachment1'?[]:'';
                    state[key] = key == 'attachment1'?[]:'';
                }
            });
            fileList.value = [];
        }
        return {
            onSubmit,
            confirm,
            refresh,
            querySubmit,
            options,
            picker,
            query,
            state,
            goToHistory,
            changeCtContractor,
            changeCtOperator,
            afterRead,
            showStatusOption,
            finished,
            loading,
            list,
            search,
            onSearch,
            del,
            getDetail,
            searchDisplay,
            previewdel,
            showCalendar,
            calendarOnConfirm,
            imgUrl,
            fileList,showImg,takephoto,checkphoto,
            add,
            status,
            isloading,
            ct_contractor_info_id_option_search,
            ct_operator_info_id_option_search,
            work_charger_option_search,
            project_charger_option_search,
            optionFilter
        }
    },
}
</script>
